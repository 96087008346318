import * as React from 'react';
import Helmet from 'react-helmet';

import { Intro } from 'components/intro/Intro';
import { Highlight } from 'components/intro/Highlight';

export default () => (
  <>
    <Helmet title="Form endpoints for Freelancers" />

    <Intro>
      <Highlight>Form</Highlight> endpoints for <Highlight>Freelancers</Highlight>.
    </Intro>
  </>
);
